:root {
    --selected-color: rgb(169, 115, 3);
    --dragover-color: rgb(213, 174, 94);
}

html, body, #root {
    height: 100%;
    overflow: auto;
}

.App {
    text-align: center;
}

* {
    box-sizing: border-box;
}

.selectable {
    position: relative;
}

.el-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.element {
    width: 100%;
}

.element[contenteditable="true"] {
    cursor: text;
}

.element:focus-visible {
    outline: none;
}


.tree.hover-selected {
    border: none;
    background-color: rgba(255, 255, 255, 0.12);
}

.tree.selected,
.tree.hover-selected.selected {
    border: none;
    background-color: var(--selected-color);
}

.height-sizer, .width-sizer {
    position: absolute;
    border: var(--selected-color) solid 1.5px;
    background-color: white;
    z-index: 10;
}

.height-sizer:hover, .width-sizer:hover {
    background-color: var(--selected-color);
}

.height-sizer {
    width: 25px;
    height: 6px;
}

.width-sizer {
    width: 6px;
    height: 25px;
}

.width-sizer:hover {
    cursor: ew-resize;
}

.height-sizer:hover {
    cursor: ns-resize;
}

.rectangle-sizer{
    position: relative;
    border: var(--selected-color) solid 2px;
    background-color: transparent;
}

.tree.hover-bottom {
    border-bottom: var(--dragover-color) solid 1px;
}

.tree.hover-top {
    border-top: var(--dragover-color) solid 1px;
}

.tree.hover-all{
    background-color: var(--dragover-color);
    border-width: 0;
    color:black;
}

.tree-dragging {
    opacity: 0.3!important;
}

.quick-actions {
    position: absolute;
    top: -26px;
    right: -2px;
    width: 50%;
    max-width: 50px;
    background-color: var(--selected-color);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    padding: 5px 10px;
    color: #efe8ff;
    font-size: 13px;
    line-height: 12px;
}

.quick-actions div:hover {
    color: white;
    cursor: pointer;
    transition: all .5s;
}

h1,h2,h3,h4,h5 {
    margin: 0;
}

@font-face {
    font-family: 'Frontier';
    src: url('fonts/Alphacentauri.woff') format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
    color: white !important;
}

div.hover-all {
    background-color: var(--dragover-color) !important;
}

div.hover-all > .element {
    background-color: transparent !important;
}

.grabbing * {
    cursor: grabbing;
}
